<template>
    <div>用户手册</div>
  </template>
    
  <script setup>
import { getFieldDataAPI } from '@/apis/fieldAPI'
import { onMounted, ref } from 'vue'

const fieldData = ref({})
const getFieldData = async () => {
  const res = await getFieldDataAPI()
  fieldData.value = res.result
}
onMounted(()=>getFieldData())

  </script>
    
  <style scoped lang="less">
  </style>