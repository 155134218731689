import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '@/views/login/index.vue'
import Layout from '@/views/layout/index.vue'
import Overview from '@/views/overview/index.vue'
import Environment from '@/views/data/environment/index.vue'
import Energy from '@/views/data/energy/index.vue'
import Warning from '@/views/data/warning/index.vue'
import Sensor from '@/views/list/sensor/index.vue'
import Equipment from '@/views/list/equipment/index.vue'
import Control from '@/views/manage/control/index.vue'
import WarningManage from '@/views/manage/warning/index.vue'
import Field from '@/views/information/field/index.vue'
import User from '@/views/information/user/index.vue'
import Manual from '@/views/manual/index.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/backstage',
    name: 'backstage',
    component: Layout,
    children: [
      {
        path: '',
        name: 'overview',
        component: Overview
      },
      {
        path: '/environment',
        name: 'environment-data',
        component: Environment
      },
      {
        path: '/energy',
        name: 'energy-data',
        component: Energy
      },
      {
        path: '/warning',
        name: 'warning-data',
        component: Warning
      },
      {
        path: '/sensor',
        name: 'sensor-list',
        component: Sensor
      },
      {
        path: '/equipment',
        name: 'equipment-list',
        component: Equipment
      },
      {
        path: '/control',
        name: 'environment-control',
        component: Control
      },
      {
        path: '/warning-manage',
        name: 'warning-manage',
        component: WarningManage
      },
      {
        path: '/field',
        name: 'field-manage',
        component: Field
      },
      {
        path: '/user',
        name: 'user-manage',
        component: User
      },
      {
        path: '/user-manual',
        name: 'user-manual',
        component: Manual
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
