<template>
  <!-- 后台头部组件 -->
  <el-menu
    :default-active="activeIndex"
    class="header-menu"
    mode="horizontal"
    :ellipsis="false"
    @select="handleSelect"
  >
    <!-- logo部分 -->
    <el-menu-item index="0">
      <img style="width: 100px" src="@/assets/logo.png" alt="logo" />
    </el-menu-item>
    <!-- 畜舍选择 -->
    <el-menu-item index="2" class="choose">
      <el-select
        v-model="barnValue"
        value-key="id"
        placeholder="选择畜舍"
        style="width: 180px"
        @change="itemChange"
      >
        <el-option
          v-for="item in barnList"
          :key="item.id"
          :label="item.barnName"
          :value="item"
        />
      </el-select>
    </el-menu-item>
    <!-- 退出登录 -->
    <div class="flex-grow" />
      <el-menu-item index="3" @click="logout">退出登录</el-menu-item>
  </el-menu>
</template>

<script setup>
import { useBarnStore, useFieldStore } from "@/stores/barn";
import { getBarnListAPI } from "@/apis/overviewAPI";
import { onMounted, ref, watch } from "vue";
import { useUserStore } from '@/stores/user'
import { useRouter } from "vue-router";

//从仓库获取当前场区id
const fieldStore = useFieldStore();

//定义切换列表选项以更新仓库中畜舍id值
const barnStore = useBarnStore();
const itemChange = (e) => {
  barnStore.changeBarnId(e.id);
  barnStore.changeBarnName(e.barnName);
};

//连接后台数据,构建畜舍列表
const barnValue = ref({
  id: "",
  barnName: "",
});
const barnList = ref([]);
const getBarnList = async () => {
  const res = await getBarnListAPI(fieldStore.fieldId);
  barnList.value = res.data;
  barnValue.value = res.data[0]
};
//获取数据
getBarnList();

//退出登录
const userStore = useUserStore()
const router = useRouter();
const logout = () =>{
  userStore.clearToken()
  router.push({ path: "/" });
}

//页面刷新函数
onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      barnValue.value = {
        id:barnStore.barnId,
        barnName:barnStore.barnName
      };
    }
  );
});

</script>

<style scoped lang="less">
.header-menu {
  width: 100%;
  height: 60px;
  left: 50%;
  transform: translateX(-50%);
}
.flex-grow {
  flex-grow: 1;
}

</style>