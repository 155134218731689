import instance from "@/utils/request"
const baseURL = '/sensor';

/**
 * 修改传感器信息
 * @param {JSON} param 请求参数
 * @returns 响应数据
 */
export const updateSensorAPI = (param) => {
    return instance({
        url:baseURL,
        method:'put',
        data:param
    })
}

/**
 * 获取传感器类别列表
 * @param {JSON} param 请求参数
 * @returns 响应数据
 */
export const querySensorCategoryListAPI = (param) => {
    return instance({
        url:baseURL + '/list',
        params:param
    })
}

/**
 * 获取环境传感器列表
 * @param {JSON} param 请求参数
 * @returns 
 */
export const queryEnvironmentSensorAPI = (param) => {
    return instance({
        url:baseURL + '/environment',
        params:param
    })
}

/**
 * 获取设备控制器列表
 * @param {JSON} param 请求参数
 * @returns 
 */
export const queryEquipmentSensorAPI = (param) =>{
    return instance({
        url:baseURL + '/equipment',
        params:param
    })
}

/**
 * 获取传感器名称列表
 * @param {JSON} param 参数列表
 * @returns 响应数据
 */
export const querySensorNameListAPI = (param) =>{
    return instance({
        url:baseURL+'/nameList',
        params:param
    })
}