import instance from "@/utils/request"
const baseURL = '/user/';

/**
 * 获取当前用户信息
 * @param {Number} id 用户id
 * @returns 响应结果
 */
export const getUserMessageAPI = (id) => {
    return instance({
        url: baseURL + id,
    })
}