<template>
  <div class="card">
    <div class="title">报警记录</div>
    <el-row class="tool-row" align="middle">
      <el-col :span="3"> 类别选择 </el-col>
      <el-col :span="6">
        <el-select
          v-model="categorySelect"
          multiple
          clearable
          collapse-tags
          collapse-tags-tooltip
          placeholder="全部"
          style="width: 100%"
        >
          <el-option
            v-for="(item, index) in categoryList"
            :key="index"
            :value="item"
          /> </el-select
      ></el-col>
    </el-row>
    <el-row class="tool-row" align="middle" style="margin-top: 0">
      <el-col :span="3"> 时间选择 </el-col>
      <el-col :span="14">
        <el-date-picker
          v-model="timeSelect"
          type="datetimerange"
          unlink-panels
          range-separator="到"
          start-placeholder="起始时间"
          end-placeholder="截止时间"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </el-col>
      <el-col :span="2">
        <el-button @click="select" type="primary">选择</el-button>
      </el-col>
    </el-row>
    <el-table class="table" :data="dataList" border="true">
      <el-table-column
        type="index"
        label="序号"
        align="center"
        width="60"
        :index="indexMethod"
      />
      <el-table-column prop="dateTime" label="时间" width="200" />
      <el-table-column prop="category" label="报警类别" width="120" />
      <el-table-column prop="content" label="内容" />
      <el-table-column prop="dealTime" label="处理时间" width="200" />
    </el-table>
    <el-row class="tool-row" align="middle" style="height: 50px; margin-top: 0">
      <el-col :span="2">
        <el-button @click="output" type="primary">导出</el-button>
      </el-col>
      <el-col :span="14" :offset="8">
        <el-pagination
          class="pagination"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="totalNumber"
          :current-page="currentPage"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
  </div>
</template>
  
<script setup>
import { useBarnStore } from "@/stores/barn";
import { queryWarningDataAPI, queryWarningNameListAPI } from "@/apis/dataAPI";
import Download from "@/utils/export"
import { onMounted, ref, watch } from "vue";

//获取畜舍id
const barnStore = useBarnStore();

//表格页码相关
const totalNumber = ref(10);
const pageSize = ref(10);
const currentPage = ref(1);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  queryWarningData();
};
//重构表格序号
const indexMethod = (index) =>
  index + (currentPage.value - 1) * pageSize.value + 1;

//参数相关
const categorySelect = ref([]);
const timeSelect = ref([]);
const selectClear = () => {
  (categorySelect.value = []), (timeSelect.value = []);
};

//接收数据
const categoryList = ref([]);
const dataList = ref([]);
const rawData = ref([]);
let barnId = "";
let barnName = ""
let name = "";
let begin = "";
let end = "";

//参数相关
const queryCategoryList = async () => {
  const { data } = await queryWarningNameListAPI({
  });
  categoryList.value = data;
};
const queryWarningData = async () => {
  barnId = barnStore.barnId;
  barnName = barnStore.barnName
  begin = timeSelect.value==null? undefined:timeSelect.value[0];
  end = timeSelect.value==null? undefined:timeSelect.value[1];
  name = categorySelect.value.toString();
  const { data } = await queryWarningDataAPI({
    barnId: barnId,
    pageNo: currentPage.value,
    pageSize: pageSize.value,
    name: name,
    begin: begin,
    end: end,
  });
  totalNumber.value = data.total;
  dataList.value = data.list;
};

const queryRawData = async()=>{
  const {data} = await queryWarningDataAPI({
    barnId: barnId,
    pageSize: 0,
    name: name,
    begin: begin,
    end: end,
  })
  rawData.value = data.list
}


//数据初始化
queryCategoryList();
queryWarningData();

//选择按钮函数
const select = () => {
  currentPage.value = 1
  queryWarningData();
};
const output = async () => {
  await queryRawData();
  Download(rawData.value, barnName + "—报警记录");
};


onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      selectClear();
      queryWarningData();
      queryCategoryList();
    }
  );
});
</script>
  
<style scoped lang="less">
.card {
  width: 100%;
  min-height: 550px;
  background-color: white;
  .title {
    font-family: @title;
    font-weight: bold;
    font-size: 22px;
    height: 30px;
    line-height: 30px;
    padding-top: 20px;
    padding-left: 35px;
  }
  .tool-row {
    width: 850px;
    height: 50px;
    left: 35px;
    margin-top: 15px;
  }
  .table {
    width: 850px;
    height: 440px;
    left: 50%;
    transform: translateX(-50%);
    font-family: @text;
    color: black;
  }
  .pagination {
    position: relative;
    float: right;
  }
}
</style>