import * as XLSX from 'xlsx'

/**
 * 数据导出excel
 * @param {JSON} data 导出数据
 * @param {String} fileName 文件名
 */
const exportToExcel = (data, fileName) => {
    // 创建一个工作簿
    const workbook = XLSX.utils.book_new();
  
    // 创建一个工作表
    const worksheet = XLSX.utils.json_to_sheet(data);
  
    // 将工作表添加到工作簿
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
    // 生成Excel文件
    XLSX.writeFile(workbook, fileName + '.xls');
  };

  export default exportToExcel;
