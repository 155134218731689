import instance from "@/utils/request"
const baseURL = '/warningRule';

/**
 * 修改报警规则
 * @param {JSON} param 请求参数
 * @returns 响应数据
 */
export const updateWarningRuleAPI = (param) => {
    return instance({
        url:baseURL,
        method:'put',
        data:param
    })
}

/**
 * 获取报警规则
 * @param {String} barnId 畜舍id
 * @returns 响应数据
 */
export const queryWarningRuleAPI = (barnId) => {
    return instance({
        url:baseURL + '/' + barnId,
    })
}