import instance from "@/utils/request"
const baseURL = '/field/';

/**
 * 获取所有场区信息
 * @returns 响应数据
 */
export const getFieldDataAPI = () =>{
    return instance({
        url: baseURL,
    })
}

/**
 * 获取当前场区信息
 * @param {Number} id 场区id
 * @returns 响应结果
 */
export const getFieldMessageAPI = (id) => {
    return instance({
        url: baseURL + id,
    })
}