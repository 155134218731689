import instance from "@/utils/request"

/**
 * 登录校验
 * @returns 返回结果
 */
export const loginAPI = (param) =>{
    return instance({
        url: "/login",
        method:'post',
        data:param
    })
}