<template>
  <div class="title">箱体总览</div>
  <environment-card
    v-for="(item, index) in barnList"
    :key="index"
    :barnId="item.id"
    :barnName="item.barnName"
  ></environment-card>
</template>
    
<script setup>
import EnvironmentCard from "./components/environment-card.vue";
import { useFieldStore } from "@/stores/barn";
import { getBarnListAPI } from "@/apis/overviewAPI";
import { ref } from "vue";

//获取场区id
const fieldStore = useFieldStore();

//连接后台数据,构建畜舍列表
const barnList = ref([]);
const getBarnList = async () => {
  const res = await getBarnListAPI(fieldStore.fieldId);
  barnList.value = res.data;
};
//获取数据
getBarnList();
</script>
    
<style scoped lang="less">
.title {
  height: 30px;
  line-height: 30px;
  font-family: SimHei;
  font-weight: bold;
  font-size: 18px;
}
</style>