import instance from "@/utils/request"

const baseURL = '/overview/'
/**
 * 获取当前场区畜舍列表
 * @param {Number} fieldId 场区id
 * @returns 响应结果
 */
export const getBarnListAPI = (fieldId) => {
    return instance({
        url: '/barn/' + fieldId,
    })
}

/**
 * 获取当前舍目前环境数据
 * @param {String} barnId 畜舍id
 * @returns 响应结果
 */
export const getEnvironmentDataAPI = (barnId) => {
    return instance({
        url: baseURL + '/environmentData/' +  barnId,
    })
}

/**
 * 获取当前舍目前能源数据
 * @param {String} barnId 畜舍id
 * @returns 响应结果
 */
export const getEnergyDataAPI = (barnId) => {
    return instance({
        url: baseURL + '/energyData/' +  barnId,
    })
}

/**
 * 获取当前舍报警数
 * @param {String} barnId 畜舍id
 * @returns 响应结果
 */
export const countWarningDataAPI = (barnId) => {
    return instance({
        url: baseURL + '/warningData/' +  barnId,
    })
}

/**
 * 获取当前舍传感器数
 * @param {String} barnId 畜舍id
 * @returns 响应结果
 */
export const countSensorAPI = (barnId) => {
    return instance({
        url: baseURL + '/sensor/' +  barnId,
    })
}