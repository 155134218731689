import instance from "@/utils/request"
const baseURL = '/controlRule';

/**
 * 修改环控规则
 * @param {JSON} param 请求参数
 * @returns 响应数据
 */
export const updateControlRuleAPI = (param) => {
    return instance({
        url:baseURL,
        method:'put',
        data:param
    })
}

/**
 * 获取环控规则
 * @param {String} barnId 畜舍id
 * @returns 响应数据
 */
export const queryControlRuleAPI = (barnId) => {
    return instance({
        url:baseURL + '/' + barnId,
    })
}