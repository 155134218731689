<template>
  <div class="card">
    <div class="title">报警规则</div>
    <!-- 温度报警 -->
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="5"> 温度报警规则（℃） </el-col>
    </el-row>
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="3">
        <el-checkbox label="高温报警" v-model="checkTemHigh"></el-checkbox>
      </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="temHigh"
          size="small"
          :controls="false"
          :min="temLow"
        ></el-input-number>
      </el-col>
      <el-col :span="3" :offset="2">
        <el-checkbox label="低温报警" v-model="checkTemLow"></el-checkbox>
      </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="temLow"
          size="small"
          :controls="false"
          :max="temHigh"
        ></el-input-number>
      </el-col>
    </el-row>
    <!-- 湿度报警 -->
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="5"> 湿度报警规则（%） </el-col>
    </el-row>
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="3">
        <el-checkbox label="高湿报警" v-model="checkRhHigh"></el-checkbox>
      </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="rhHigh"
          size="small"
          :controls="false"
          :min="rhLow"
        ></el-input-number>
      </el-col>
      <el-col :span="3" :offset="2">
        <el-checkbox label="低湿报警" v-model="checkRhLow"></el-checkbox>
      </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="rhLow"
          size="small"
          :controls="false"
          :max="rhHigh"
        ></el-input-number>
      </el-col>
    </el-row>
    <!-- 气体报警 -->
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="5"> 气体报警规则（ppm） </el-col>
    </el-row>
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="3">
        <el-checkbox label="浓度阈值" v-model="checkGasHigh"></el-checkbox>
      </el-col>
      <el-col :span="2">
        <el-input-number
          v-model="gasHigh"
          size="small"
          :controls="false"
          min="0"
        ></el-input-number>
      </el-col>
    </el-row>
    <!-- 修改按钮 -->
    <el-row class="tool-row" align="middle">
      <el-col :span="2">
        <el-button @click="update" type="primary">修改</el-button>
      </el-col>
    </el-row>
  </div>
</template>
    
<script setup>
import { useBarnStore } from "@/stores/barn";
import { queryWarningRuleAPI, updateWarningRuleAPI } from "@/apis/warningManageAPI";
import { onMounted, ref, watch } from "vue";

//获取畜舍id
const barnStore = useBarnStore();

//接收数据
const ruleId = ref("");
const temHigh = ref("");
const temLow = ref("");
const rhHigh = ref("");
const rhLow = ref("");
const gasHigh = ref("");

const checkTemHigh = ref(true);
const checkTemLow = ref(true);
const checkRhHigh = ref(true);
const checkRhLow = ref(true);
const checkGasHigh = ref(true);

const deviceName = ref("");

//参数相关
const queryWarningRule = async () => {
  const { data } = await queryWarningRuleAPI(barnStore.barnId);
  ruleId.value = data.id;
  deviceName.value = data.deviceName;
  temHigh.value = data.temHigh;
  checkTemHigh.value = data.temHighActive;
  temLow.value = data.temLow;
  checkTemLow.value = data.temLowActive;
  rhHigh.value = data.rhHigh;
  checkRhHigh.value = data.rhHighActive;
  rhLow.value = data.rhLow;
  checkRhLow.value = data.rhLowActive;
  gasHigh.value = data.gasHigh;
  checkGasHigh.value = data.gasHighActive;
};
const updateWarningRule = async () => {
  const {data} = await updateWarningRuleAPI({
    id: ruleId.value,
    deviceName: deviceName.value,
    temHigh:temHigh.value,
    temHighActive:checkTemHigh.value,
    temLow:temLow.value,
    temLowActive:checkTemLow.value,
    rhHigh:rhHigh.value,
    rhHighActive:checkRhHigh.value,
    rhLow:rhLow.value,
    rhLowActive:checkRhLow.value,
    gasHigh:gasHigh.value,
    gasHighActive:checkGasHigh.value
  })
}

//按钮赋值
const update = () => {
  updateWarningRule();
};
//数据初始化
queryWarningRule();

//页面刷新函数
onMounted(() => {
  watch(
    () => barnStore.barnId,
    () => {
      queryWarningRule();
    }
  );
});
</script>
    
<style scoped lang="less">
.card {
  width: 100%;
  min-height: 550px;
  background-color: white;
  .title {
    font-family: @title;
    font-weight: bold;
    font-size: 22px;
    height: 30px;
    line-height: 30px;
    padding-top: 20px;
    padding-left: 35px;
  }
  .tool-row {
    width: 850px;
    height: 40px;
    left: 35px;
    margin-top: 15px;
    :deep(.el-input-number--small) {
      width: 80px;
    }
  }
}
</style>