import instance from "@/utils/request"
const baseURL = '/barn';

/**
 * 新增畜舍信息
 * @param {JSON} param 请求参数
 * @returns 响应结果
 */
export const saveBarnAPI = (param) => {
    return instance({
        url:baseURL,
        method:'post',
        data:param
    })
}

/**
 * 删除畜舍信息
 * @param {String} id 畜舍id
 * @returns 响应结果
 */
export const removeBarnAPI = (id) => {
    return instance({
        url:baseURL + '/' + id,
        method:'delete',
    })
}

/**
 * 修改畜舍信息
 * @param {JSON} param 请求参数
 * @returns 响应数据
 */
export const updateBarnAPI = (param) => {
    return instance({
        url:baseURL,
        method:'put',
        data:param
    })
}

/**
 * 获取畜舍信息
 * @param {JSON} param 请求参数
 * @returns 响应数据
 */
export const queryBarnAPI = (param) => {
    return instance({
        url:baseURL,
        params:param
    })
}