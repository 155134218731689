/**
 * 定义全局常量
 */

//数据调用
export const WATER_METER = '水表';
export const POWER_METER = '电表';

export const ENVIRONMENT_SENSOR = '环境传感器';
export const EQUIPMENT_SENSOR = '设备控制器';
export const ENERGY_SENSOR = '能源传感器';

export const TEMPERATURE = '温度';
export const HUMIDITY = '湿度';
export const WIND_SPEED = '风速';
export const CARBON_DIOXIDE = '二氧化碳浓度';