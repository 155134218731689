import axios from 'axios'
import { ElMessage } from 'element-plus'
import {useUserStore} from '@/stores/user'
import router from '@/router'

const baseURL = '/api'
const instance = axios.create({ baseURL:baseURL, timeout:2000 })

//axios请求拦截器
instance.interceptors.request.use(config => {
    //获取token
    const userStore = useUserStore()
    //拼接token
    const token = userStore.token
    if(token){
        config.headers.token = token
    }
    return config
}, e => Promise.reject(e))

//axios响应式拦截器
instance.interceptors.response.use(
    res => {
        if (res.data.code===1) {
            return res.data;
        }
        if(res.data.msg === 'NOT_LOGIN'){
            router.replace('/');
        }
        ElMessage({
            type:'warning',
            message:res.data.msg
        })
        return Promise.reject(res.data)
    }, e => {
        ElMessage({
            type:'warning',
            message:e.response.data.msg
        })
    return Promise.reject(e)
})

export default instance