import instance from "@/utils/request"

/**
 * 分页查询环境数据
 * @param {JSON} param 请求参数
 * @returns 响应结果
 */
export const queryEnvironmentDataAPI = (param) =>{
    return instance({
        url: '/environmentData',
        params:param
    })
}

/**
 * 分页查询能源数据
 * @param {JSON} param 请求参数
 * @returns 响应结果
 */
export const queryEnergyDataAPI = (param) => {
    return instance({
        url:'/energyData',
        params:param
    })
}

/**
 * 获取报警种类列表
 * @returns 响应结果
 */
export const queryWarningNameListAPI = () =>{
    return instance({
        url:'/warningData/nameList'
    })
}

/**
 * 分页查询报警数据
 * @param {JSON} param 请求参数
 * @returns 响应结果
 */
export const queryWarningDataAPI = (param) =>{
    return instance({
        url:'warningData',
        params:param
    })
}