import { defineStore } from "pinia";
import {loginAPI} from "@/apis/loginAPI" 
import { ref } from "vue";

export const useUserStore = defineStore('user', ()=>{
    //定义用户state
    const token = ref('');
    const userId = ref(5);
    //定义获取数据函数
    const getToken = async(data) => {
        const res = await loginAPI(data);
        token.value = res.data.jwt;
        userId.value = res.data.userId;
    }
    //退出清除用户信息
    const clearToken = () => {
        token.value = ''
    }
    return{
        token,
        userId,
        getToken,
        clearToken
    }
},{
    persist:true,
})