<template>
  <div class="card">
    <div class="title">用户信息</div>
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="3"> 用户名 </el-col>
      <el-col :span="8"> {{ userData.userName }} </el-col>
    </el-row>
    <el-row class="tool-row" align="middle">
      <el-col :span="3"> 真实姓名 </el-col>
      <el-col :span="8"> {{ userData.realName }} </el-col>
    </el-row>
    <el-row class="tool-row" align="middle">
      <el-col :span="3"> 联系方式 </el-col>
      <el-col :span="8"> {{ userData.phone }} </el-col>
    </el-row>
    <el-row class="tool-row" align="middle">
      <el-col :span="3"> 管理员类别 </el-col>
      <el-col :span="8"> {{ userData.level == 1? "超级管理员" : "普通管理员" }} </el-col>
    </el-row>
    <div class="title" style="margin-top: 50px">场区信息</div>
    <el-row class="tool-row" align="middle" style="margin-top: 10px">
      <el-col :span="3"> 场区名称 </el-col>
      <el-col :span="8"> {{ fieldData.name }} </el-col>
    </el-row>
    <el-row class="tool-row" align="middle">
      <el-col :span="3"> 场区类型 </el-col>
      <el-col :span="8"> {{ fieldData.category }} </el-col>
    </el-row>
    <el-row class="tool-row" align="middle">
      <el-col :span="3">  场区地址 </el-col>
      <el-col :span="8"> {{ fieldData.address }} </el-col>
    </el-row>
  </div>
</template>
  
<script setup>
import { useUserStore } from "@/stores/user";
import { useFieldStore } from "@/stores/barn";
import { getUserMessageAPI } from "@/apis/userAIP"
import { getFieldMessageAPI } from "@/apis/fieldAPI";
import { onMounted, ref, watch } from "vue";

//获取用户id
const userStore = useUserStore();
let userId = userStore.userId;
//获取场区id
const fieldStore = useFieldStore();
let fieldId = fieldStore.fieldId;

//接收数据
const userData = ref([]);
const fieldData = ref([]);

//参数相关
const queryUserData = async () => {
  const { data } = await getUserMessageAPI(userId);
  userData.value = data;
};
const queryFieldData = async () => {
  const { data } = await getFieldMessageAPI(fieldId);
  fieldData.value = data;
};

//数据初始化
queryUserData();
queryFieldData();

</script>
  
<style scoped lang="less">
.card {
  width: 100%;
  min-height: 550px;
  background-color: white;
  .title {
    font-family: @title;
    font-weight: bold;
    font-size: 22px;
    height: 30px;
    line-height: 30px;
    padding-top: 20px;
    padding-left: 35px;
  }
  .tool-row {
    width: 850px;
    height: 40px;
    left: 35px;
  }
}
</style>