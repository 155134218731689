<template>
    <!-- 弹窗 -->
    <el-dialog
      v-model="props.modifyRule"
      :title="props.title"
      width="500"
      align-center
      :close-on-click-modal="false"
    >
      <el-form
        :model="tempData"
        label-width="auto"
        style="max-width: 400px"
        :rules="rules"
        ref="formRef"
      >
        <el-form-item label="箱体名称" prop="name">
            <el-input
            v-model="tempData.barnName"
            size="small"
            style="left: 15px"
          />
        </el-form-item>
        <el-form-item label="箱体类别" prop="category">
            <el-input
            v-model="tempData.category"
            size="small"
            style="left: 15px"
          />
        </el-form-item>
        <el-form-item label="种植量" prop="quantity">
            <el-input
            type="number"
            v-model="tempData.quantity"
            size="small"
            style="left: 15px"
          />
        </el-form-item>
        <el-form-item label="最大种植量" prop="maxQuantity">
            <el-input
            type="number"
            v-model="tempData.maxQuantity"
            size="small"
            style="left: 15px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </template>
  
  <script setup>
  import { onMounted, ref, toRaw, watch } from "vue";
  
  //表单相关
  const formRef = ref(null);
  
  //接收父组件数据
  const props = defineProps({
    title: String,
    modifyRule: Boolean,
    rowData: Object,
    isSave:Boolean
  });
  const tempData = ref({
    id:"",
    barnName: "",
    category: "",
    quantity: "",
    maxQuantity: "",
    fieldId: "",
  });
  
  const rules = {
    barnName: { required: true, message: "请写入箱体名称", trigger: "blur" },
    category: { required: true, message: "请写入类别", trigger: "blur" },
  };
  
  //传回父组件参数
  const emit = defineEmits(["submitData", "closeDialog", "saveData"]);
  
  //按钮函数
  const submit = () => {
    formRef.value.validate((valid) => {
      if (!valid) {
        console.log("录入信息有误，请补充输入");
        return false;
      }
      if(props.isSave){
        emit("saveData", tempData.value);
      }else{
        emit("submitData", tempData.value);
      }
    });
  };
  const cancel = () => {
    emit("closeDialog");
  };
  
  onMounted(() => {
    watch(
      () => props.rowData,
      () => {
        Object.assign(tempData.value, toRaw(props.rowData));
      }
    );
  });
  </script>
  
  <style>
  </style>