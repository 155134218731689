<template>
    <!-- 弹窗 -->
    <el-dialog
    v-model="props.modifyRule"
    title="修改信息"
    width="500"
    align-center
    :close-on-click-modal="false"
  >
    <el-form :model="tempData" label-width="auto" style="max-width: 400px">
      <el-form-item label="仪器编号">
        <el-input
          v-model="tempData.sensorId"
          size="small"
          disabled
          style="left: 15px"
        />
      </el-form-item>
      <el-form-item label="仪器类别">
        <el-input
          v-model="tempData.category"
          size="small"
          disabled
          style="left: 15px"
        />
      </el-form-item>
      <el-form-item label="仪器名称">
        <el-input
          v-model="tempData.name"
          size="small"
          style="left: 15px"
        />
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          v-model="tempData.note"
          size="small"
          style="left: 15px"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { onMounted, ref, toRaw, watch } from "vue";

//接收父组件数据
const props = defineProps({
    modifyRule:Boolean,
    rowData:Object
})
const tempData = ref({
    sensorId:'',
    category:'',
    name:'',
    note:''
})

//传回父组件参数
const emit = defineEmits(['submitData','closeDialog'])

//按钮函数
const submit = () => {
    emit('submitData', tempData.value)
}
const cancel = () =>{
    emit('closeDialog')
}

onMounted(() => {
  watch(
    () => props.rowData,
    () => {
        Object.assign(tempData.value,toRaw(props.rowData))
    }
  );
});
</script>

<style>

</style>