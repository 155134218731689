import { defineStore } from "pinia";
import { ref } from 'vue'

/**
 * 记录当前箱体id
 */
export const useBarnStore = defineStore('barn', () => {
    //定义数据
    const barnId = ref(1)
    const barnName = ref('测试箱1')
    //定义修改数据方法
    const changeBarnId = (id) => {
        barnId.value = id
    }
    const changeBarnName = (name) =>{
        barnName.value = name
    }
    //return返回供使用
    return {
        barnId,
        barnName,
        changeBarnId,
        changeBarnName
    }
})

/**
 * 记录当前场区id
 */
export const useFieldStore = defineStore('field', () => {
    const fieldId = ref(1)
    const fieldName = '种植试验场'
    const fieldIdList = ref([])
    const changeFieldId = (id) => {
        fieldId.value = id
    }
    const changeFieldName = (name) => {
        fieldName.value = name
    }

    return{
        fieldId,
        fieldName,
        fieldIdList,
        changeFieldId,
        changeFieldName
    }
})